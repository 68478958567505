import React from 'react';

const Logo = () => (
	<div>
		<svg version="1.1" id="Layer_1" x="0px" y="0px"
			viewBox="0 0 255.5 300" >
		<g class="st0">
			<path class="st1" d="M0.5,95.3C4.9,54.8,30,10.6,53.5,2.5C56.5,1.8,58.7,1,60.2,1c3.7-0.7,9.6,3.7,11.8,8.1
				c1.5,0.7,2.9,2.9,2.9,2.9c0,0.7,0.7-0.7,1.5-2.2c2.9-2.9,6.6-2.9,7.4,0.7c0.7,1.5-0.7,6.6-2.2,11.8c-9.6,42-5.9,72.2,11,72.2
				c7.4,0,22.1-11.8,41.2-33.9c8.8-11,13.3-14,13.3-10.3c0,1.5-27.2,33.9-33.9,39c-8.8,7.4-14,10.3-21.4,10.3c-5.2,0-8.1-0.7-10.3-2.9
				C71.2,89.4,69,82,69,61.4V45.9l-5.2,11c-16.2,36.1-39,64.8-52.3,64.8C3.5,121.8-1.7,110.8,0.5,95.3z M16.7,113
				c9.6-5.2,25.8-26.5,37.6-50.1c9.6-19.1,18.4-42.7,17.7-45.7c-0.7-1.5-2.2-3.7-3.7-5.2C62.4,6.9,55.7,9.1,45.4,18
				C29.2,33.4,16.7,57.7,10.8,84.2C8.6,96,8.6,110,10.8,113C12.3,115.2,12.3,115.2,16.7,113z"/>
			<path class="st1" d="M80.8,272c28-33.1,44.2-54.5,64.1-84.7c14-21.4,15.5-24.3,25-61.9c3.7-13.3,17.7-82.5,16.9-82.5l-5.9,8.8
				c-18.4,28-33.9,39-42.7,30.9c-5.9-5.9-2.2-20.6,9.6-41.2c11.8-19.9,25.8-34.6,38.3-39.8c7.4-2.9,13.3-2.2,16.9,2.2
				c3.7,3.7,3.7,6.6,1.5,10.3c-1.5,2.9-4.4,12.5-5.9,22.1l-8.8,41.2c-5.9,29.5-10.3,47.9-13.3,59.6c-0.7,1.5,3.7-3.7,8.1-11
				c22.8-32.4,62.6-78.1,68.5-78.1c4.4,0,2.9,3.7-5.9,12.5c-10.3,11-21.4,23.6-33.9,39c-11.8,15.5-44.9,64.8-47.1,72.2
				c-10.3,28.7-24.3,55.2-38.3,75.9c-25,36.1-56,61.1-61.9,49.3C64.6,292.6,68.3,286,80.8,272z M85.2,284.5
				c17.7-14,36.1-38.3,50.8-65.5l6.6-14c0,0-6.6,8.8-14.7,19.1c-7.4,10.3-22.1,28-31.7,39.8c-20.6,23.6-24.3,28-22.1,28
				C74.9,291.9,80,289,85.2,284.5z M176.5,45.9c7.4-10.3,17.7-29.5,19.9-35.3c0-1.5,0.7-3.7,1.5-4.4c0.7-2.2-2.9-2.2-9.6,1.5
				c-12.5,5.2-28,24.3-39.8,47.1c-5.2,11-7.4,22.8-3.7,22.8C151.5,77.6,164,65.1,176.5,45.9z"/>
		</g>
		</svg>
	</div>
)

export default Logo;